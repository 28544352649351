<script>
import { BFormRadio } from 'bootstrap-vue'

export default {
  components: { BFormRadio },
  props: [
    'heading',
    'required',
    'options',
    'heading2',
    'flexClass',
    'radioHeadingClass',
    'RadioBtnMainContainerClass',
    'title',
    'from',
  ],
  data() {
    return {
      selected: '',
    }
  },
  methods: {
    saveInput() {
      this.$store.commit('saveInput', {
        title: this.title,
        value: this.selected,
        from: this.from,
      })
    },
  },
}
</script>

<template>
  <div class="FormRadio  d-sm-block" :class="RadioBtnMainContainerClass">
    <h1 class="FormRadio__heading" :class="radioHeadingClass">
      <span v-if="heading" class="heading1">{{ heading }}</span>
      <span v-if="required" class="text-danger">*</span>
    </h1>
    <div :class="flexClass">
      <BFormRadio
        v-for="(option, i) in options" :key="i" v-model="selected" class="FormRadio__btn"
        :class="option.class" :options="options" :value="option.value" @change="saveInput"
      >
        <div :class="option.textContainerClass">
          <p class="FormRadio__btn__text" :class="option.text1class" v-html="option.text" />
          <p
            v-if="option.text2" class="FormRadio__btn__text" :class="option.text2class"
            v-html="option.text2"
          />
        </div>
      </BFormRadio>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
